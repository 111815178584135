import {
    ComboBox,
    DropdownMenuItemType,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    Icon,
    Persona,
    PersonaSize,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import SearchComboField from 'components/Field/SearchComboField';
import Fuse from 'fuse.js';
import { useSelector } from 'hooks';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { map, orderBy } from 'lodash';
import ProfessionalSuffixInput from 'pages/components/ProfessionalSuffixInput';
import { useCallback, useEffect, useRef, useState } from 'react';

import { selectProviderProfiles, selectSelectedProviderProfile } from 'state/slices/providerProfiles/providerProfiles.selectors';
import { setSelectProviderProfile, updateProviderProfileProp } from 'state/slices/providerProfiles/providerProfiles.slice';

import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import {
    getProfessionalSuffixes,
    professionalSuffixCategories,
} from 'state/slices/professionalsuffixes/professionalsuffixes.slice';

import ProviderMapping from './ProviderMapping';

function UserOverview() {
    const {
        selectedUser,
        updateSubscriptionUserIdentityProperty,
        updateSubscriptionUserProperty,
        users,
        usersNotInSubscription,
    } = useSubscriptionUsers();
    const { productId } = useParams<{ productId: string }>();

    const [selectedDropdownUserId, setSelectedDropdownUserId] = useState<string | undefined>();
    const [results, setResults] = useState<IComboBoxOption[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const comboBoxRef = useRef<IComboBox>(null);
    const openMenu = useCallback(() => comboBoxRef.current?.focus(true), []);

    const dispatch = useDispatch();

    const providerProfiles = useSelector(selectProviderProfiles);

    const providerProfilesList = map(providerProfiles);

    const profSuffixOptions = useSelector(professionalSuffixCategories);

    const selectedProfile = useSelector(selectSelectedProviderProfile);

    useEffect(() => {
        dispatch(getProfessionalSuffixes());
    }, []);

    const getUserNPIAndSpecialty = () => {
        if (selectedProfile?.providerTypeMapping) {
            return (
                <Stack>
                    <TextField
                        label="License Number"
                        value={selectedProfile?.licenseNumber}
                        onChange={(ev, value) => {
                            dispatch(updateProviderProfileProp({ path: 'licenseNumber', value }));
                        }}
                    />
                    <TextField label="NPI" value={selectedProfile?.npi?.toString()} readOnly disabled />
                    <TextField label="Specialty" value={selectedProfile?.specialty} readOnly disabled />
                </Stack>
            );
        }
    };

    const fuse = new Fuse([] as ITenantUser[], {
        threshold: 0.2,
        keys: [{ name: 'identity.displayName', weight: 1 }],
        findAllMatches: true,
        useExtendedSearch: true,
    });

    const fuseSearch = () => {
        const results = fuse.search(search ? `'${search}` : '');
        const mappedResults: IDropdownOption[] = orderBy(
            results.map((user) => ({
                key: user.item.identity.id,
                text: `${user.item.identity.firstName} ${user.item.identity.lastName} ${user.item.identity.email}`,
            })),
            (option) => {
                const splitText = option.text.split(' ');
                const lastName = splitText[1];
                return lastName;
            },
        );
        setResults(mappedResults);
    };

    useEffect(() => {
        fuse.setCollection(usersNotInSubscription as ITenantUser[]);
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, usersNotInSubscription]);

    const _updateIsDeleted = (ev?: React.MouseEvent<HTMLElement | MouseEvent>, checked?: boolean) => {
        if (selectedUser) {
            updateSubscriptionUserProperty('isDeleted', !checked);
        }
    };

    const isEditing = selectedUser ? (users[selectedUser.id] ? true : false) : false;

    const isAddingNewUser = selectedDropdownUserId === 'addUser';

    useEffect(() => {
        if (selectedDropdownUserId !== undefined) {
            if (isAddingNewUser) {
                const newIdentity: IUserIdentity = {
                    id: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                };

                updateSubscriptionUserProperty('identity', newIdentity);
            } else {
                const userIdentity = usersNotInSubscription.find((u) => u.identity.id === selectedDropdownUserId)?.identity;
                if (userIdentity) updateSubscriptionUserProperty('identity', userIdentity);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDropdownUserId, updateSubscriptionUserProperty]);

    useEffect(() => {
        const provider = providerProfilesList.find((res) => res?.id === selectedUser?.identity.providerId);
        if (provider) dispatch(setSelectProviderProfile(provider));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser?.identity.providerId]);

    const dropdownOptions: IDropdownOption[] = orderBy(
        usersNotInSubscription.map((user) => ({
            key: user.identity.id,
            text: `${user.identity.firstName} ${user.identity.lastName} ${user.identity.email}`,
        })),
        (option) => {
            const splitText = option.text.split(' ');
            const lastName = splitText[1];
            return lastName;
        },
    );

    const _onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
        if (option) {
            const splitText = option.text.split(' ');
            if (option.key !== 'addUser' && option.key !== 'Header') {
                const fullName = `${splitText[0]} ${splitText[1]}`;
                const email = splitText[2];
                const fullNameInitials = fullName
                    .split(' ')
                    .map((name) => name[0].toUpperCase())
                    .join('');

                return (
                    <Stack grow verticalAlign="center">
                        <Persona
                            text={fullName}
                            secondaryText={email}
                            size={PersonaSize.size8}
                            showSecondaryText
                            imageInitials={fullNameInitials}
                        />
                    </Stack>
                );
            } else if (option.key !== 'Header') {
                return (
                    <Stack horizontal tokens={{ childrenGap: 5 }} grow>
                        <Icon iconName="Add" />
                        <span>{option.text}</span>
                    </Stack>
                );
            } else {
                return <span>{option.text}</span>;
            }
        }
        return null;
    };
    const options = results.length ? results : dropdownOptions;
    return (
        <Stack>
            {!isEditing && (
                <Stack.Item grow>
                    <ComboBox
                        componentRef={comboBoxRef}
                        allowFreeform
                        label="Select Existing/Add User"
                        placeholder="(Select Existing/Add User)"
                        selectedKey={selectedDropdownUserId}
                        options={[
                            { key: 'addUser', text: 'Add User' },
                            {
                                key: 'Header',
                                text: 'Existing Users',
                                itemType: DropdownMenuItemType.Header,
                            },
                            { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
                            ...options,
                        ]}
                        onRenderOption={_onRenderOption}
                        onInput={(e) => {
                            const value = (e.target as unknown as { value: string }).value;
                            setSearch(value === '' ? undefined : value);
                            openMenu();
                        }}
                        onBlur={() => {
                            setSearch(undefined);
                        }}
                        onChange={(e, option, index, value) => {
                            if (option) {
                                setSelectedDropdownUserId(option.key as string);
                                setSearch(undefined);
                            } else {
                                setSearch(undefined);
                            }
                        }}
                        useComboBoxAsMenuWidth
                    />
                </Stack.Item>
            )}
            {(isEditing || isAddingNewUser) && (
                <>
                    <Stack.Item>
                        <TextField
                            value={selectedUser?.identity.email}
                            label="Email (Username)"
                            onChange={(ev, value) => updateSubscriptionUserIdentityProperty('email', value?.trim())}
                            disabled={isEditing}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <Stack.Item grow>
                                <TextField
                                    label="First Name"
                                    value={selectedUser?.identity.firstName}
                                    onChange={(ev, value) => updateSubscriptionUserIdentityProperty('firstName', value)}
                                    autoComplete="off"
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <TextField
                                    label="Last Name"
                                    value={selectedUser?.identity.lastName}
                                    onChange={(ev, value) => updateSubscriptionUserIdentityProperty('lastName', value)}
                                    autoComplete="off"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ProfessionalSuffixInput
                                    maxResults={1000}
                                    onChange={(_, options) => {
                                        if (options) {
                                            updateSubscriptionUserIdentityProperty(
                                                'professionalSuffix',
                                                options.key ? options.key : '',
                                            );
                                        }
                                    }}
                                    selectedKey={selectedUser?.identity.professionalSuffix}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </>
            )}

            {productId !== 'membership' && <ProviderMapping />}

            {isEditing && (
                <Stack.Item grow>
                    <Toggle
                        checked={selectedUser?.isDeleted ? false : true}
                        label="Subscription Status"
                        onText="Active"
                        offText="Disabled"
                        onChange={_updateIsDeleted}
                    />
                </Stack.Item>
            )}

            {selectedProfile ? getUserNPIAndSpecialty() : null}
        </Stack>
    );
}

export default UserOverview;
