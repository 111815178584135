import { DirectionalHint, PrimaryButton, Stack, Toggle, TooltipHost } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { addNewAdjustmentReason } from 'state/slices/adjustment-reasons/adjustment-reasons.actions';
import { selectAdjustmentReasonsShowActive } from 'state/slices/adjustment-reasons/adjustment-reasons.selectors';
import { toggleAdjustmentReasonsToggleShowActive } from 'state/slices/adjustment-reasons/adjustment-reasons.slice';
import AdjustmentReasonsList from './AdjustmentReasonsList';
import AdjustmentReasonsMessageBar from './AdjustmentReasonsMessageBar';

export default function AdjustmentReasons() {
    const dispatch = useDispatch();

    const showActive = useSelector(selectAdjustmentReasonsShowActive);

    return (
        <ActivityPage
            hasPageHeader
            title="Patient Adjustment Reasons"
            rightContent={
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Toggle
                        inlineLabel
                        label="Show Active"
                        styles={{ root: { margin: 0 } }}
                        checked={showActive}
                        onChange={() => {
                            dispatch(toggleAdjustmentReasonsToggleShowActive());
                        }}
                    />
                    <TooltipHost directionalHint={DirectionalHint.bottomCenter} content="Add new Adjustment Reason">
                        <PrimaryButton
                            text="Adjustment Reason"
                            iconProps={{ iconName: 'Add' }}
                            onClick={() => {
                                dispatch(addNewAdjustmentReason());
                            }}
                        />
                    </TooltipHost>
                </Stack>
            }
        >
            <AdjustmentReasonsMessageBar />
            <AdjustmentReasonsList />
        </ActivityPage>
    );
}
