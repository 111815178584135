import { MessageBarType } from '@fluentui/react';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import IAdjustmentCategory from 'api/models/AdjustmentCategory.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type AdjustmentReasonsState = {
    /*Loading status for adjustment reasons*/
    loadingReasonsStatus: LoadingStatus;
    /*Loading status for adjustment categories*/
    loadingCategoriesStatus: LoadingStatus;
    /*Show active adjustment reasons only*/
    showActive: boolean;
    /*Message bar message text for adjustment reasons page*/
    messageBarMessage?: string;
    /*Message bar type for adjustment reasons page*/
    messageBarType?: MessageBarType;
    /*All adjustment reasons*/
    reasons: Record<string, IAdjustmentReason>;
    /*All adjustment categories*/
    categories: Record<string, IAdjustmentCategory>;

    //Edit state properties:

    /*Adjustment reason to edit*/
    editReason?: IAdjustmentReason;
    /*Adjustment reason panel open*/
    editReasonOpen?: boolean;
    /*Saving status for an adjustment reason*/
    savingStatus: LoadingStatus;
};

const initialAdjustmentReasonsState: AdjustmentReasonsState = {
    loadingReasonsStatus: LoadingStatus.Idle,
    loadingCategoriesStatus: LoadingStatus.Idle,
    showActive: true,
    reasons: {},
    categories: {},
    savingStatus: LoadingStatus.Idle,
};

export default initialAdjustmentReasonsState;
