import { MessageBar } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectAdjustmentReasonsMessageBar } from 'state/slices/adjustment-reasons/adjustment-reasons.selectors';
import { cleanupAdjustmentReasonMessageBar } from 'state/slices/adjustment-reasons/adjustment-reasons.slice';

export default function AdjustmentReasonsMessageBar() {
    const dispatch = useDispatch();
    const { messageBarType, messageBarMessage } = useSelector(selectAdjustmentReasonsMessageBar);

    if (!messageBarType || !messageBarMessage) return null;

    return (
        <MessageBar
            onDismiss={() => {
                dispatch(cleanupAdjustmentReasonMessageBar());
            }}
            messageBarType={messageBarType}
        >
            {messageBarMessage}
        </MessageBar>
    );
}
