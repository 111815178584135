import { NoMatch } from 'pages';
import CustomerSelection from 'pages/CustomerSelection/CustomerSelection.container';
import LocationsOfCare from 'pages/LocationsOfCare/LocationsOfCare';
import LandingPage from 'pages/LandingPage/LandingPage';
import SlidingFee from 'pages/SlidingFee/SlidingFee';
import Subscriptions from 'pages/Subscriptions/Subscriptions';

import { RouteProps } from 'react-router';
import ProceduresDetails from 'pages/Procedures/ProceduresDetails';
import EncounterReasons from 'pages/EncounterReasons/EncounterReasons';
import Payers from 'pages/Payers/Payers';
import AppointmentCancellationReasons from 'pages/AppointmentCancellationReasons/AppointmentCancellationReasons';
import { Permission, permissionsAsList } from 'hooks/usePermissions';
import Users from 'pages/Users/users';
import StatementConfiguration from 'pages/PatientStatement/StatementConfig';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';
import AdjustmentReasons from 'pages/AdjustmentReasons/AdjustmentReasons';

export interface IExtendedRouteProps extends RouteProps {
    routes?: IExtendedRouteProps[];
    permissions?: Permission[];
    featureFlag?: FeatureFlag;
    layout?: 'noSideBar' | 'sideBar';
}

const routes: IExtendedRouteProps[] = [
    {
        path: '/',
        component: CustomerSelection,
        exact: true,
        layout: 'noSideBar',
    },
    {
        path: '/:tenantId/',
        component: LandingPage,
        exact: true,
        layout: 'sideBar',
        permissions: permissionsAsList,
    },
    {
        path: '/:tenantId/subscriptions/',
        component: Subscriptions,
        layout: 'sideBar',
        permissions: [Permission.DentalSubscription, Permission.MembershipSubscription],
    },
    // {
    //     path: '/:tenantId/subscriptions/:productId',
    //     component: Subscriptions,
    //     exact: true,
    //     layout: 'sideBar',
    // },
    {
        path: '/:tenantId/users',
        component: Users,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.Users],
    },
    {
        path: '/:tenantId/procedures',
        component: ProceduresDetails,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.ProcedureDetails],
    },
    {
        path: '/:tenantId/sliding-fee/',
        component: SlidingFee,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.Programs],
    },
    {
        path: '/:tenantId/sliding-fee/:programId',
        component: SlidingFee,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.Users],
    },
    {
        path: '/:tenantId/sliding-fee/:programId/plan',
        component: SlidingFee,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.Programs],
    },
    {
        path: '/:tenantId/payers/',
        component: Payers,
        layout: 'sideBar',
        permissions: [Permission.Payers],
    },
    // {
    //     path: '/:tenantId/payers/:payerId',
    //     component: Payers,
    //     exact: true,
    //     layout: 'sideBar',
    // },
    // {
    //     path: '/:tenantId/payers/:payerId/fee-schedules/:feeScheduleId',
    //     component: Payers,
    //     exact: true,
    //     layout: 'sideBar',
    // },
    {
        path: '/:tenantId/locationsOfCare',
        component: LocationsOfCare,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.LocationsOfCare],
    },
    {
        path: '/:tenantId/appointmentCancellationReasons',
        component: AppointmentCancellationReasons,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.AppointmentCancellationReason],
    },
    {
        path: '/:tenantId/encounterReasons',
        component: EncounterReasons,
        exact: true,
        layout: 'sideBar',
        permissions: [Permission.EncounterReasons],
    },
    {
        path: '/:tenantId/adjustment-reasons',
        component: AdjustmentReasons,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: '/:tenantId/statementConfiguration',
        layout: 'sideBar',
        component: StatementConfiguration,
        permissions: [Permission.StatementConfiguration],
        featureFlag: FeatureFlag.AutomatedStatementsConfiguration,
    },
    {
        path: '*',
        exact: true,
        component: NoMatch,
        layout: 'noSideBar',
    },
];

export default routes;
