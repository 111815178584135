import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { RootState } from 'state/store';
import {
    selectAdjustmentReasonsLookup,
} from './adjustment-reasons.selectors';
import { setAdjustmentReasonEditModalAdjustmentReason, setAdjustmentReasonEditModalOpen } from './adjustment-reasons.slice';
import { v4 as uuid } from 'uuid';
import IAdjustmentCategory from 'api/models/AdjustmentCategory.model';

export const getAdjustmentReasons = createAsyncThunk<
    Record<string, IAdjustmentReason>,
    { tenantId: string },
    { rejectValue: string }
>('adjustmentReasons/getAdjustmentReasons', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.getAdjustmentReasons(tenantId);
        return request.data;
    } catch (e) {
        return rejectWithValue(JSON.stringify(e));
    }
});

export const getAdjustmentCategories = createAsyncThunk<
    Record<string, IAdjustmentCategory>,
    { tenantId: string },
    { rejectValue: string }
>('adjustmentReasons/getAdjustmentCategories', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.getAdjustmentCategories(tenantId);
        return request.data;
    } catch (e) {
        return rejectWithValue(JSON.stringify(e));
    }
});

export const upsertEditAdjustmentReason = createAsyncThunk<
    IAdjustmentReason | undefined,
    { tenantId: string; adjustmentReason: IAdjustmentReason; isAddingAdjustmentReason?: boolean },
    { rejectValue: string }
>(
    'adjustmentReasons/upsertEditAdjustmentReason',
    async ({ tenantId, adjustmentReason, isAddingAdjustmentReason }, { rejectWithValue }) => {
        try {
            let data: IAdjustmentReason | undefined;

            if (isAddingAdjustmentReason) {
                const request = await membershipApi.createAdjustmentReason(tenantId, adjustmentReason);
                data = request.data;
            } else {
                const request = await membershipApi.updateAdjustmentReason(tenantId, adjustmentReason);
                data = request.data;
            }

            return data;
        } catch (e) {
            return rejectWithValue(JSON.stringify(e));
        }
    },
);

export const editAdjustmentReason = (adjustmentReasonId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setAdjustmentReasonEditModalOpen(true));
    dispatch(setAdjustmentReasonEditModalAdjustmentReason(selectAdjustmentReasonsLookup(getState())[adjustmentReasonId]));
};

export const addNewAdjustmentReason = () => (dispatch: Dispatch) => {
    dispatch(setAdjustmentReasonEditModalOpen(true));
    dispatch(setAdjustmentReasonEditModalAdjustmentReason({ id: uuid(), isDeleted: false, displayName: '', displayInDental: true }));
};
