import { Stack, Text, Toggle } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useSelector } from 'hooks';
import { Permission } from 'hooks/usePermissions';
import useTenantId from 'hooks/useTenantId';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DunningMessage from './DunningMessage';
import StatementDetails from './StatementDetails';
import { StatementConfigurationBar } from './components/StatementConfigurationBar';
import { getStatementConfigurations, updateStatementConfiguration } from './state/actions';
import { statementConfigurations } from './state/selectors';
import UserDisplayName from 'components/UserDisplayName';
import { format } from 'date-fns';
export interface IStatementConfigurationLink {
    name: string;
    key: string;
    url: string;
    permission: Permission;
}

function StatementConfiguration() {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const config = useSelector(statementConfigurations);

    useEffect(() => {
        dispatch(getStatementConfigurations({ tenantId }));
    }, []);

    const patientStatementLink: IStatementConfigurationLink[] = [
        {
            name: 'Dunning Message',
            key: 'dunning-Message',
            url: 'dunning-Message',
            permission: Permission.StatementConfiguration,
        },
        {
            name: 'Statement Details',
            key: 'statement-Details',
            url: 'statement-Details',
            permission: Permission.StatementConfiguration,
        },
    ];

    return (
        <ActivityPage
            title="Statement Configuration"
            hideFooter
            hasPageHeader
            leftContent={
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <Toggle
                        checked={!config?.isDeleted}
                        inlineLabel
                        label="Active"
                        styles={{ root: { marginBottom: 0 } }}
                        onClick={() => {
                            if (config)
                                dispatch(
                                    updateStatementConfiguration({
                                        tenantId,
                                        statementConfiguration: { ...config, isDeleted: !config?.isDeleted },
                                    }),
                                );
                        }}
                    />
                </Stack>
            }
            rightContent={
                <Text>
                    Last modified: <UserDisplayName userId={config?.modifiedBy} />{' '}
                    {config?.modifiedOn ? `(${format(new Date(config.modifiedOn), 'MM/dd/yyyy')})` : ''}
                </Text>
            }
        >
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <StatementConfigurationBar statementConfigurationLink={patientStatementLink} />
                <Stack styles={{ root: { padding: 10, display: 'flex' } }} grow>
                    <Stack grow>
                        <Switch>
                            <Route path={`/${tenantId}/statementConfiguration/dunning-Message`} component={DunningMessage} />
                            <Route path={`/${tenantId}/statementConfiguration/statement-Details`} component={StatementDetails} />
                        </Switch>
                    </Stack>
                </Stack>
            </Stack>
        </ActivityPage>
    );
}

export default StatementConfiguration;
