import { IComboBox, IComboBoxOption, IconButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import SearchComboField from 'components/Field/SearchComboField';
import { useSelector } from 'hooks';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { refreshProviderProfiles } from 'state/slices/providerProfiles/providerProfiles.actions';
import { selectProviderProfilesAsList, selectProvidersNotInUse } from 'state/slices/providerProfiles/providerProfiles.selectors';
import { setSelectProviderProfile } from 'state/slices/providerProfiles/providerProfiles.slice';
import { selectProviderIdInUse } from 'state/slices/subscriptions/users/users.selectors';
import { getUserTasksByTarget } from 'state/slices/tasks/actions';
import { UserTaskTarget } from 'state/slices/tasks/model';
import { selectUserTasksByTarget, TaskType } from 'state/slices/tasks/selectors';

function ProviderMapping() {
    const { selectedUser, updateSubscriptionUserIdentityProperty } = useSubscriptionUsers();
    const providerProfilesNotInUse = useSelector(selectProvidersNotInUse);

    const { tenantId } = useParams<{
        tenantId: string;
    }>();
    const dispatch = useDispatch();

    const options: IComboBoxOption[] = [
        { text: 'Unmapped', key: '000' },

        ...providerProfilesNotInUse.map((providerProfile) => ({
            key: providerProfile?.id as string,
            text: `${providerProfile?.firstName} ${providerProfile?.lastName} | ${providerProfile?.providerTypeMapping} | ${providerProfile?.id}`,
            data: providerProfile,
        })),
    ];

    const isDuplicated = useSelector(selectProviderIdInUse);
    const hasDuplicateError = isDuplicated ? 'Provider is already being used.' : '';


    const _onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {

        dispatch(setSelectProviderProfile(option?.data));
        updateSubscriptionUserIdentityProperty('providerId', option?.key);
    };

    const _removeMapping = () => {
        updateSubscriptionUserIdentityProperty('providerId', '000');
        dispatch(setSelectProviderProfile(undefined));
    };

    const task = useSelector(selectUserTasksByTarget);

    useEffect(() => {
        dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableRefreshTask = task?.some((t) => t.type === TaskType.RefreshingProviderProfiles);

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
            <Stack.Item grow>
                <SearchComboField
                    label="Provider Mapping"
                    selectedKey={selectedUser?.identity.providerId}
                    options={options}
                    onChange={_onChange}
                    maxResults={1000}
                    useComboBoxAsMenuWidth
                    errorMessage={hasDuplicateError}
                />
            </Stack.Item>
            {!disableRefreshTask ? (
                <IconButton
                    iconProps={{ iconName: 'Refresh' }}
                    title="Refresh Provider Profiles"
                    onClick={() => {
                        dispatch(refreshProviderProfiles({ tenantId }));
                    }}
                />

            ) : (
                <Spinner size={SpinnerSize.medium} />
            )}

            <IconButton
                primary
                iconProps={{ iconName: 'ChromeClose' }}
                title="Remove provider mapping"
                onClick={_removeMapping}
                disabled={!selectedUser?.identity.providerId || selectedUser?.identity.providerId === '000'}
            />
        </Stack>
    );
}

export default ProviderMapping;
