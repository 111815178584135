
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IProviderProfile } from 'api/models/providerProfile.model';
import ProviderProfileState from './providerProfiles.state';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getProviderProfiles, updateProviderProfile, refreshProviderProfiles } from './providerProfiles.actions';

const providerProfilesReducers = {
    setSelectProviderProfile: (state: ProviderProfileState, action: PayloadAction<IProviderProfile | undefined>) => {
        state.selectedProviderProfile = action.payload;
    },

    updateProviderProfileProp: (
        state: ProviderProfileState,
        action: PayloadAction<{ path: keyof IProviderProfile; value: any }>,
    ) => {
        const { path, value } = action.payload;
        if (state.selectedProviderProfile) {
            (state.selectedProviderProfile[path] as any) = value;
        }
    },

    cleanupSelectedProviderProfile: (state: ProviderProfileState) => {
        state.selectedProviderProfile = undefined;
    },
};


export default providerProfilesReducers;

export const providerProfilesExtraReducers = (builder: ActionReducerMapBuilder<ProviderProfileState>) => {
    return builder
        .addCase(getProviderProfiles.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getProviderProfiles.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.data = action.payload;
        })
        .addCase(getProviderProfiles.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(updateProviderProfile.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(updateProviderProfile.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            if (action.payload.provider) {
                state.data[action.payload.provider.id] = action.payload.provider;
            }
        })
        .addCase(updateProviderProfile.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(refreshProviderProfiles.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(refreshProviderProfiles.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.data = { ...state.data, ...action.payload };
        })
        .addCase(refreshProviderProfiles.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
};
