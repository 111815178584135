import { IconButton, Stack, useTheme } from '@fluentui/react';

import 'components/ActivityBar/ActivityBar.scss';

import { useParams } from 'react-router';

import { PropsWithChildren } from 'react';
import useSubscriptions from 'hooks/store/useSubscriptions';
import { ITenantSubscription } from 'api/models/tenantSubscription.model';
import { Permission } from 'hooks/usePermissions';

import SubscriptionBarLink from './SubscriptionBarLink';
import { useDispatch } from 'react-redux';

export interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    permissions?: Permission[];
}

type Props = {
    subscriptions: ITenantSubscription[];
};

export const subscriptionPermissionMap: Record<string, Permission> = {
    dental: Permission.DentalSubscription,
    membership: Permission.MembershipSubscription,
};

function SubscriptionBar(props: PropsWithChildren<Props>) {
    const theme = useTheme();
    const params = useParams<{ tenantId: string; productId: string }>();
    const { tenantId } = params;

    const { isExpanded, toggleExpanded } = useSubscriptions();

    const activities: IActivity[] = props.subscriptions.map((subscription: ITenantSubscription) => {
        return {
            keyString: subscription.productId,
            path: `/${tenantId}/subscriptions/${subscription.productId}`,
            tooltip: subscription.product.displayName,
            exact: true,
            permissions: [subscriptionPermissionMap[subscription.product.id]],
        };
    });

    const { neutralLighterAlt } = theme!.palette!;

    const activityLinks = activities.map((activity) => (
        <SubscriptionBarLink key={activity.keyString} expanded={isExpanded} {...activity} />
    ));

    const wrapperClassName = isExpanded ? 'activityBar activityBar--expanded' : 'activityBar';

    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };

    const expanderIcon = {
        iconName: isExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    const _setExpanded = () => {
        toggleExpanded();
    };

    if (activityLinks.length > 0) {
        return (
            <Stack className={wrapperClassName} style={wrapperStyle}>
                <Stack style={{ flex: 1 }}>
                    <Stack.Item grow>{activityLinks}</Stack.Item>
                </Stack>

                <div className={'activityBar__footer'}>
                    <IconButton onClick={_setExpanded} iconProps={expanderIcon} />
                </div>
            </Stack>
        );
    }

    return <></>;
}

export default SubscriptionBar;
