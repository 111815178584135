import { DisplayNameSupport, GeneralSupports } from './support-types';

type AdjustmentReasonSupports = GeneralSupports & DisplayNameSupport;

export enum AdjustFrom {
    Patient = "Patient",
    Insurance = "Insurance",
    Both = "Both",
}

export default interface IAdjustmentReason extends AdjustmentReasonSupports {
    isSystemUseOnly?: boolean;
    isCollectable?: boolean;
    isPositiveAdjustment?: boolean;
    adjustmentCategoryId?: string;
    displayInDental: boolean;
    adjustFrom?: AdjustFrom;
}
