import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateProviderProfile } from 'state/slices/providerProfiles/providerProfiles.actions';
import { selectSelectedProviderProfile } from 'state/slices/providerProfiles/providerProfiles.selectors';
import { selectProviderIdInUse } from 'state/slices/subscriptions/users/users.selectors';
import UserOverview from './UserOverview';
import UserPermissions from './UserPermissions';
import { setSelectProviderProfile } from 'state/slices/providerProfiles/providerProfiles.slice';

type Props = { isOpen: boolean; closePanel: () => void };

function EditUserPanel({ isOpen, closePanel }: Props) {
    const { selectedUser, saving, users, selectSubscriptionUsersAsList } = useSubscriptionUsers();
    const { tenantId, productId } = useParams<{
        tenantId: string;
        productId: string;
    }>();

    const dispatch = useDispatch();
    const selectedProfile = useSelector(selectSelectedProviderProfile);

    const _isSaving = saving === LoadingStatus.Pending;
    useEffect(() => {
        if (saving === LoadingStatus.Completed) {
            closePanel();
        }
        return;
    }, [saving, closePanel]);

    const onDismissed = () => {
        closePanel();
        dispatch(setSelectProviderProfile(undefined));
    };

    const isEditing = selectedUser ? (users[selectedUser.id] ? true : false) : false;

    const _panelHeadingText = `${isEditing ? 'Edit' : 'Add'} Subscription User`;

    const _saveUserAndIdentity = () => {
        if (selectedUser) {

            dispatch(updateProviderProfile({ tenantId, provider: selectedProfile, user: selectedUser, productId }));

        }
    };

    const _isDuplicateEmail = selectSubscriptionUsersAsList.find(
        (account) => account.identity.email === selectedUser?.identity?.email,
    )
        ? true
        : false;
    const _isDuplicateEmailMessage =
        _isDuplicateEmail && !isEditing ? (
            <MessageBar messageBarType={MessageBarType.blocked}> Email already exists</MessageBar>
        ) : (
            ''
        );

    const _disabledDuplicate = !isEditing && _isDuplicateEmail ? true : false;

    const isProviderDuplicated = useSelector(selectProviderIdInUse);

    const disableSave = _isSaving || _disabledDuplicate || isProviderDuplicated;

    const _onRenderFooterContent = () => {
        const savingText = _isSaving ? 'Saving...' : 'Save';

        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                {_isDuplicateEmailMessage}
                <PrimaryButton
                    text={savingText}
                    disabled={disableSave}
                    iconProps={{ iconName: 'SaveAndClose' }}
                    onClick={_saveUserAndIdentity}
                />
                <DefaultButton text="Cancel" disabled={_isSaving} onClick={closePanel} />
            </Stack>
        );
    };
    return (
        <Panel
            isOpen={isOpen}
            onDismiss={closePanel}
            onDismissed={onDismissed}
            headerText={_panelHeadingText}
            type={PanelType.medium}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <UserOverview />

            <UserPermissions />
        </Panel>
    );
}

export default EditUserPanel;
