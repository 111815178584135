import { Dictionary } from '@reduxjs/toolkit';
import ApiClient, { IApiEndpointConfig } from './apiClient';
import authProvider from './auth/authProvider';
import IServiceUserAccount, { IUserIdentity } from './models/account.model';
import ITenantAccount, { ITenantGroup, ITenantUser } from './models/tenantAccount.model';
import { AxiosRequestConfig } from 'axios';
import ISlidingFeeProgram from './models/slidingFee.model';
import { IProcedure, IProcedureSearch } from './models/procedure.model';
import ILocationOfCare from './models/locationsOfCare.model';
import IProcedureDetail from './models/procedure-details.model';
import { IPayer, IPayerDetail } from 'api/models/payer.model';
import { IProviderProfile } from './models/providerProfile.model';
import { IEncounter } from 'api/models/encounter.model';
import { ITenantSubscription } from './models/tenantSubscription.model';

import { IProviderProfileSavePayload } from 'state/slices/providerProfiles/providerProfiles.actions';

import IProfessionalSuffixes from './models/professionalSuffixes.model';
import ITenant from './models/tenant.model';
import IAdjustmentReason from './models/adjustment-reason.model';
import IAdjustmentCategory from './models/AdjustmentCategory.model';
import { IProcedureCategory } from './models/procedure-category.model';


export const MembershipApiConfig: IApiEndpointConfig = {
    baseUrl: process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_AppApiUri}/api/` : '#{AppApiUri}#/api/',



    scopes: process.env.NODE_ENV === 'development' ? [`${process.env.REACT_APP_ApiAuthScope}`] : ['#{ApiAuthScope}#'],
    authProvider: authProvider,
};

class MembershipApi extends ApiClient {
    constructor() {
        super(MembershipApiConfig);
    }
    // Locations Of Care =====================================
    getAllLocationsOfCare(tenantId: string) {
        return this.client.get<Dictionary<ILocationOfCare>>(`Tenants/${tenantId}/LocationsOfCare`);
    }
    getLocationOfCareById(tenantId: string, departmentId: string) {
        return this.client.get<ILocationOfCare>(`Tenants/${tenantId}/LocationsOfCare/${departmentId}`);
    }
    createLocationOfCare(tenantId: string, department: ILocationOfCare) {
        return this.client.post<ILocationOfCare>(`Tenants/${tenantId}/LocationsOfCare`, department);
    }
    searchLocationOfCare(tenantId: string, queryObj: { displayName?: string; description?: string }) {
        return this.client.post<ILocationOfCare>(`Tenants/${tenantId}/LocationsOfCare/Search`, queryObj);
    }
    updateLocationOfCare(tenantId: string, department: ILocationOfCare) {
        return this.client.put(`Tenants/${tenantId}/LocationsOfCare`, department);
    }

    refreshLocationsOfCare(tenantId: string) {
        return this.client.get<Dictionary<ILocationOfCare>>(`Tenants/${tenantId}/LocationsOfCare/Refresh`);
    }

    // Account =====================================
    getAccount() {
        return this.client.get<IServiceUserAccount>(`Account`);
    }
    getAccountById(tenantId: string, accountId: string, config?: AxiosRequestConfig) {
        return this.client.get<IUserIdentity>(`Account/Tenants/${tenantId}/Users/${accountId}`, config);
    }

    // Tenant Groups =====================================
    getTenantGroups(tenantId: string) {
        return this.client.get<Dictionary<ITenantGroup>>(`Tenants/${tenantId}/Groups`);
    }
    getTenantGroupById(tenantId: string, groupId: string) {
        return this.client.get<ITenantGroup>(`Tenants/${tenantId}/Groups/${groupId}`);
    }
    createTenantGroup(tenantId: string, group: ITenantGroup) {
        return this.client.post<ITenantGroup>(`Tenants/${tenantId}/Groups`, group);
    }
    updateTenantGroup(tenantId: string, group: ITenantGroup) {
        return this.client.put(`Tenants/${tenantId}/Groups`, group);
    }

    // Tenant Users =====================================
    /**
     * account: ITenantUserModel
     * user: IUserIdentity
     */
    getTenantUsers(tenantId: string) {
        return this.client.get<Dictionary<ITenantUser>>(`Tenants/${tenantId}/Users`);
    }
    getTenantUserById(tenantId: string, user: string) {
        return this.client.get<ITenantUser>(`Tenants/${tenantId}/Users/${user}`);
    }
    updateTenantUser(tenantId: string, user: ITenantUser) {
        return this.client.put<ITenantUser>(`Tenants/${tenantId}/Users`, user);
    }
    addOrUpdateIdentity(tenantId: string, identity: IUserIdentity) {
        return this.client.put<IUserIdentity>(`Tenants/${tenantId}/Users/Identities`, identity);
    }

    // Tenant Subscriptions =====================================
    getTenantSubscriptions(tenantId: string) {
        return this.client.get<ITenantSubscription[]>(`Tenants/${tenantId}/Subscriptions`);
    }

    // Tenant Subscription Users =====================================
    getTenantSubscriptionUsers(tenantId: string, productId: string) {
        return this.client.get<Dictionary<ITenantUser>>(`Tenants/${tenantId}/Subscriptions/${productId}/Users`);
    }
    updateTenantSubscriptionUser(tenantId: string, productId: string, user: ITenantUser) {
        return this.client.put<ITenantUser>(`Tenants/${tenantId}/Subscriptions/${productId}/Users`, user);
    }

    // Tenant =====================================
    getTenants() {
        return this.client.get<ITenant[]>(`Tenants`);
    }
    getTenantById(tenantId: string) {
        return this.client.get<ITenant>(`Tenants/${tenantId}`);
    }

    // SlidingFeePrograms =====================================
    getSlidingFeeProgramById(tenantId: string, programId: string) {
        return this.client.get<ISlidingFeeProgram>(`Tenants/${tenantId}/SlidingFeePrograms/${programId}`);
    }
    getSlidingFeePrograms(tenantId: string) {
        return this.client.get<Dictionary<ISlidingFeeProgram>>(`Tenants/${tenantId}/SlidingFeePrograms`);
    }
    createSlidingFeeProgram(tenantId: string, program: ISlidingFeeProgram) {
        return this.client.post<ISlidingFeeProgram>(`Tenants/${tenantId}/SlidingFeePrograms`, program);
    }
    updateSlidingFeeProgram(tenantId: string, program: ISlidingFeeProgram) {
        return this.client.put<ISlidingFeeProgram>(`Tenants/${tenantId}/SlidingFeePrograms`, program);
    }

    // Procedures =====================================
    proceduresSearch(query: IProcedureSearch) {
        return this.client.post<Dictionary<IProcedure>>('Procedures/Search', query);
    }

    getProcedures() {
        return this.client.get<Dictionary<IProcedure>>('Procedures');
    }

    // Procedure Categories  =====================================
    getProcedureCategories() {
        return this.client.get<IProcedureCategory[]>('ProcedureCategories');
    }

    // Procedures Details =====================================
    getProcedureDetails(tenantId: string) {
        return this.client.get<Dictionary<IProcedureDetail>>(`ProcedureDetails?tenantId=${tenantId}`);
    }
    getProcedureDetailById(tenantId: string, id: string) {
        return this.client.get<IProcedureDetail>(`ProcedureDetails/${id}?tenantId=${tenantId}`);
    }
    createProcedureDetails(tenantId: string, model: IProcedureDetail) {
        return this.client.post<IProcedureDetail>(`ProcedureDetails?tenantId=${tenantId}`, model);
    }
    updateProcedureDetails(tenantId: string, model: IProcedureDetail) {
        return this.client.put<IProcedureDetail>(`ProcedureDetails?tenantId=${tenantId}`, model);
    }

    bulkUpdateProcedureDetails(tenantId: string, model: IProcedureDetail[]) {
        return this.client.put<IProcedureDetail[]>(`ProcedureDetails/List?tenantId=${tenantId}`, model);
    }

    // ExcelData ===========================
    // addExcelDiagnoses() {
    //     return this.client.get<Dictionary<IPayer>>(`Diagnoses`);
    // }
    addExcelProcedureDetails(tenantId: string, formFile: File) {
        const formData = new FormData();
        formData.append('formFile', formFile);
        return this.client.post<Dictionary<IProcedureDetail>>(`ExcelData/ProcedureDetails?tenantId=${tenantId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }
    getExcelProcedureDetails(tenantId: string) {
        const response = this.client.get<Blob>(`ExcelData/ProcedureDetails?tenantId=${tenantId}`, {
            responseType: 'arraybuffer',
        });

        return response;
    }

    addExcelPayerDetailFeeSchedules(tenantId: string, payerDetailId: string, feeScheduleId: string, formFile: File) {
        const formData = new FormData();
        formData.append('formFile', formFile);
        return this.client.post<IPayerDetail>(
            `ExcelData/PayerDetailFeeSchedules?tenantId=${tenantId}&payerDetailId=${payerDetailId}&feeScheduleId=${feeScheduleId}`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        );
    }

    getExcelPayerDetailFeeSchedules(tenantId: string, payerDetailId: string, feeScheduleId: string) {
        const response = this.client.get<Blob>(
            `ExcelData/PayerDetailFeeSchedules?tenantId=${tenantId}&payerDetailId=${payerDetailId}&feeScheduleId=${feeScheduleId}`,
            {
                responseType: 'arraybuffer',
            },
        );

        return response;
    }

    // Payer Details ===========================
    getPayerDetails(tenantId: string) {
        return this.client.get<Dictionary<IPayerDetail>>(`PayerDetail?tenantId=${tenantId}`);
    }
    createPayerDetail(tenantId: string, model: IPayerDetail) {
        return this.client.post<IPayerDetail>(`PayerDetail?tenantId=${tenantId}`, model);
    }
    updatePayerDetail(tenantId: string, model: IPayerDetail) {
        return this.client.put<IPayerDetail>(`PayerDetail?tenantId=${tenantId}`, model);
    }
    getPayerDetailById(tenantId: string, payerId: string) {
        return this.client.get<IPayerDetail>(`PayerDetail/${payerId}?tenantId=${tenantId}`);
    }

    // Payers ===========================
    getPayers() {
        return this.client.get<Dictionary<IPayer>>(`Payers`);
    }
    getPayersById(payerId: string) {
        return this.client.get<IPayer>(`Payers/${payerId}`);
    }

    // Provider Profiles ===========================
    getProviderProfiles(tenantId: string) {
        return this.client.get<Dictionary<IProviderProfile>>(`/Tenants/${tenantId}/ProviderProfiles`);
    }

    updateProviderProfile(tenantId: string, model: IProviderProfileSavePayload) {
        return this.client.put<IProviderProfileSavePayload>(`/Tenants/${tenantId}/ProviderProfiles`, model);
    }

    refreshProviderProfiles(tenantId: string) {
        return this.client.get<Dictionary<IProviderProfile>>(`/Tenants/${tenantId}/ProviderProfiles/Refresh`);
    }

    createProviderProfile(tenantId: string, providerProfile: IProviderProfile) {
        return this.client.post<IProviderProfile>(`/Tenants/${tenantId}/ProviderProfiles`, providerProfile);
    }

    //Adjustment Categories ===========================
    getAdjustmentCategories(tenantId: string) {
        return this.client.get<Record<string, IAdjustmentCategory>>(`AdjustmentCategories?tenantId=${tenantId}`);
    }

    //Adjustment Reasons ===========================
    getAdjustmentReasons(tenantId: string) {
        return this.client.get<Record<string, IAdjustmentReason>>(`AdjustmentReasons?tenantId=${tenantId}`);
    }
    updateAdjustmentReason(tenantId: string, adjustmentReason: IAdjustmentReason) {
        return this.client.put<IAdjustmentReason>(`AdjustmentReasons?tenantId=${tenantId}`, adjustmentReason);
    }
    createAdjustmentReason(tenantId: string, adjustmentReason: IAdjustmentReason) {
        return this.client.post<IAdjustmentReason>(`AdjustmentReasons?tenantId=${tenantId}`, adjustmentReason);
    }
    //Encounter Reasons ===========================
    getEncounterReasons(tenantId: string) {
        return this.client.get<Dictionary<IEncounter>>(`Tenants/${tenantId}/EncounterReasons`);
    }
    getEncounterReasonsById(tenantId: string, encounterId: string) {
        return this.client.get<IEncounter>(`Tenants/${tenantId}/EncounterReasons/${encounterId}`);
    }

    createEncounterReason(tenantId: string, model: IEncounter) {
        return this.client.post<IEncounter>(`Tenants/${tenantId}/EncounterReasons`, model);
    }

    updateEncounterReason(tenantId: string, model: IEncounter) {
        return this.client.put<IEncounter>(`Tenants/${tenantId}/EncounterReasons`, model);
    }

    refreshEncounterReasons(tenantId: string) {
        return this.client.get<Dictionary<IEncounter>>(`Tenants/${tenantId}/EncounterReasons/Refresh`);
    }
    getProfessionalSuffixes() {
        return this.client.get<Dictionary<IProfessionalSuffixes>>(`ProfessionalSuffixes`);
    }
}

const membershipApi = new MembershipApi();
export default membershipApi;
