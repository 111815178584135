import { createSlice } from '@reduxjs/toolkit';
import adjustmentReasonsReducers, { adjustmentReasonsExtraReducers } from './adjustment-reasons.reducers';
import initialAdjustmentReasonsState from './adjustment-reasons.state';

const adjustmentReasonsSlice = createSlice({
    name: 'adjustmentReasons',
    reducers: adjustmentReasonsReducers,
    initialState: initialAdjustmentReasonsState,
    extraReducers: adjustmentReasonsExtraReducers,
});

const { reducer, actions } = adjustmentReasonsSlice;

export const {
    cleanupAdjustmentReasonsState,
    toggleAdjustmentReasonsToggleShowActive,
    cleanupAdjustmentReasonEditModal,
    setAdjustmentReasonEditModalOpen,
    setAdjustmentReasonEditModalAdjustmentReason,
    editAdjustmentReasonPropertyValue,
    setAdjustmentReasonMessageBar,
    cleanupAdjustmentReasonMessageBar,
    setAdjustmentReason,
} = actions;

export default reducer;
