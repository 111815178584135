import { IDropdownOption } from '@fluentui/react';
import { AdjustFrom } from 'api/models/adjustment-reason.model';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { adjustmentReasons } from '..';

const selectAdjustmentReasonsState = (state: RootState) => state.adjustmentReasons;

export const selectAdjustmentReasonsLoadingStatus = createSelector(
    selectAdjustmentReasonsState,
    (state) => state.loadingReasonsStatus,
);
export const selectAdjustmentCategoriesLoadingStatus = createSelector(
    selectAdjustmentReasonsState,
    (state) => state.loadingCategoriesStatus,
);

export const selectAdjustmentReasonsMessageBar = createSelector(selectAdjustmentReasonsState, (state) => ({
    messageBarMessage: state.messageBarMessage,
    messageBarType: state.messageBarType,
}));

export const selectAdjustmentCategoriesLookup = createSelector(selectAdjustmentReasonsState, (state) => state.categories ?? {});
export const selectAdjustmentCategoriesList = createSelector(selectAdjustmentCategoriesLookup, (lookup) => map(lookup));
export const selectFilteredAdjustmentCategories = createSelector(selectAdjustmentCategoriesList, (categories) =>
    categories.filter((category) => !category.isSystemUseOnly && !category.isDeleted),
);
export const selectAdjustmentCategoryOptions = createSelector(selectFilteredAdjustmentCategories, (adjustmentCategories) => {
    const options: IDropdownOption[] = adjustmentCategories.map((category) => ({ key: category.id, text: category.displayName }));
    return options;
});

export const selectAdjustmentReasonsLookup = createSelector(selectAdjustmentReasonsState, (state) => state.reasons ?? {});
export const selectAdjustmentReasonsList = createSelector(selectAdjustmentReasonsLookup, (lookup) => map(lookup));
export const selectAdjustmentReasonsShowActive = createSelector(selectAdjustmentReasonsState, (state) => state.showActive);
export const selectFilteredAdjustmentReasons = createSelector(
    selectAdjustmentReasonsList,
    selectAdjustmentReasonsShowActive,
    (reasons, showActive) => {
        const adjustmentReasons = [
            ...reasons.filter((reason) => {
                return !reason.isSystemUseOnly && !reason.isDeleted && reason.displayInDental && reason.adjustFrom === AdjustFrom.Patient;
            }),
        ];

        if (!showActive) adjustmentReasons.push(...reasons.filter((reason) => reason.isDeleted && reason.displayInDental && reason.adjustFrom === AdjustFrom.Patient));

        return adjustmentReasons;
    },
);

export type AdjustmentReasonView = {
    id: string;
    displayName: string;
    adjustmentCategoryDisplayName: string;
    isCollectable: boolean;
    isDeleted: boolean;
};

export const selectFilteredAdjustmentReasonViews = createSelector(
    selectFilteredAdjustmentReasons,
    selectAdjustmentCategoriesLookup,
    (reasons, categoryLookup) => {
        const views: AdjustmentReasonView[] = reasons.map((reason) => ({
            id: reason.id,
            displayName: reason.displayName,
            adjustmentCategoryDisplayName:
                reason.adjustmentCategoryId && categoryLookup[reason.adjustmentCategoryId]
                    ? categoryLookup[reason.adjustmentCategoryId].displayName
                    : '(No category associated)',
            isCollectable: !!reason.isCollectable,
            isDeleted: reason.isDeleted
        }));

        return views;
    },
);

export const selectAdjustmentReasonsEditModalOpen = createSelector(selectAdjustmentReasonsState, (state) => state.editReasonOpen);
export const selectAdjustmentReasonsEditItem = createSelector(selectAdjustmentReasonsState, (state) => state.editReason);
export const selectAdjustmentReasonsEditItemSaving = createSelector(selectAdjustmentReasonsState, (state) => state.savingStatus);
export const selectAdjustmentReasonsEditModalIsAdding = createSelector(
    selectAdjustmentReasonsEditItem,
    selectAdjustmentReasonsLookup,
    (item, lookup) => {
        if (!lookup || !item) return false;
        return !lookup[item.id];
    },
);
