import { MessageBarType } from '@fluentui/react';
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getAdjustmentCategories, getAdjustmentReasons, upsertEditAdjustmentReason } from './adjustment-reasons.actions';
import initialAdjustmentReasonsState, { AdjustmentReasonsState } from './adjustment-reasons.state';

const adjustmentReasonsReducers = {
    cleanupAdjustmentReasonsState: (state: AdjustmentReasonsState) => {
        state = initialAdjustmentReasonsState;
    },
    toggleAdjustmentReasonsToggleShowActive: (state: AdjustmentReasonsState) => {
        state.showActive = !state.showActive;
    },
    editAdjustmentReasonPropertyValue: (
        state: AdjustmentReasonsState,
        { payload }: PayloadAction<{ prop: keyof IAdjustmentReason; value: string | boolean | undefined }>,
    ) => {
        const { prop, value } = payload;
        if (state.editReason) (state.editReason as any)[prop] = value;
    },
    setAdjustmentReasonEditModalOpen: (state: AdjustmentReasonsState, { payload }: PayloadAction<boolean>) => {
        state.editReasonOpen = payload;
    },
    setAdjustmentReasonEditModalAdjustmentReason: (
        state: AdjustmentReasonsState,
        { payload }: PayloadAction<IAdjustmentReason>,
    ) => {
        state.editReason = payload;
    },
    cleanupAdjustmentReasonEditModal: (state: AdjustmentReasonsState) => {
        state.savingStatus = LoadingStatus.Idle;
        state.editReason = undefined;
    },
    setAdjustmentReasonMessageBar: (
        state: AdjustmentReasonsState,
        { payload }: PayloadAction<{ messageBarMessage: string; messageBarType: MessageBarType }>,
    ) => {
        const { messageBarType, messageBarMessage } = payload;
        state.messageBarType = messageBarType;
        state.messageBarMessage = messageBarMessage;
    },
    cleanupAdjustmentReasonMessageBar: (state: AdjustmentReasonsState) => {
        state.messageBarType = undefined;
        state.messageBarMessage = undefined;
    },
    setAdjustmentReason: (state: AdjustmentReasonsState, { payload }: PayloadAction<IAdjustmentReason>) => {
        state.reasons[payload.id] = payload;
    },
};

export const adjustmentReasonsExtraReducers = (builder: ActionReducerMapBuilder<AdjustmentReasonsState>) => {
    return builder
        .addCase(getAdjustmentCategories.pending, (state) => {
            state.loadingCategoriesStatus = LoadingStatus.Pending;
        })
        .addCase(getAdjustmentCategories.fulfilled, (state, { payload }) => {
            state.loadingCategoriesStatus = LoadingStatus.Completed;
            state.categories = payload;
        })
        .addCase(getAdjustmentCategories.rejected, (state) => {
            state.loadingCategoriesStatus = LoadingStatus.Failed;
        })
        .addCase(getAdjustmentReasons.pending, (state) => {
            state.loadingReasonsStatus = LoadingStatus.Pending;
        })
        .addCase(getAdjustmentReasons.fulfilled, (state, { payload }) => {
            state.loadingReasonsStatus = LoadingStatus.Completed;
            state.reasons = payload;
        })
        .addCase(getAdjustmentReasons.rejected, (state) => {
            state.loadingReasonsStatus = LoadingStatus.Failed;
        })
        .addCase(upsertEditAdjustmentReason.pending, (state) => {
            state.savingStatus = LoadingStatus.Pending;
        })
        .addCase(upsertEditAdjustmentReason.fulfilled, (state, { payload, meta }) => {
            state.savingStatus = LoadingStatus.Completed;
            if (payload) {
                state.reasons[payload.id] = payload;
            }
            state.editReasonOpen = false;

            if (meta.arg.isAddingAdjustmentReason) {
                state.messageBarMessage = 'Added new adjustment reason successfully.';
            } else {
                state.messageBarMessage = 'Updated adjustment reason successfully.';
            }
            state.messageBarType = MessageBarType.success;
        })
        .addCase(upsertEditAdjustmentReason.rejected, (state, { meta }) => {
            state.savingStatus = LoadingStatus.Failed;
            state.editReasonOpen = false;

            if (meta.arg.isAddingAdjustmentReason) {
                state.messageBarMessage = 'Something went wrong while trying to add new adjustment reason.';
            } else {
                state.messageBarMessage = 'Something went wrong while trying to save adjustment reason.';
            }
            state.messageBarType = MessageBarType.error;
        });
};

export default adjustmentReasonsReducers;
