
import { ChangeAuditSupport, ETagSupport, IdSupport, SoftDeleteSupport, ReferencesSupport } from './support-types';

type ProviderProfileSupports = IdSupport & ChangeAuditSupport & ETagSupport & SoftDeleteSupport & ReferencesSupport;


export interface IProviderProfile extends ProviderProfileSupports {
    firstName?: string;
    lastName?: string;
    displayName?: string;
    sourceUsername?: string;
    npi?: number;
    licenseNumber?: string;
    address: IMailingAddress;
    phone?: string;
    specialty?: string;
    taxonomy?: string;
    taxonomyDescription?: string;
    providerType?: string;
    providerTypeMapping?: string;
}

export interface IMailingAddress {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export enum ProviderTypeMapping {
    TreatingProvider = 'Treating Provider',
    Hygienist = 'Hygienist',
    Resident = 'Resident',
}
