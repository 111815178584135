import { ITheme, Stack, IconButton, useTheme } from '@fluentui/react';
import ActivityBarLink from './ActivityBarLink';
import './ActivityBar.scss';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setActivityBarExpanded } from 'state/slices/ui/ui.slice';
import { Permission, permissionsAsList } from 'hooks/usePermissions';
import { selectTaskCountsByPage } from 'state/slices/tasks/selectors';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';

type Props = {
    theme?: ITheme;
};

export interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    permissions?: Permission[];
    featureFlag?: FeatureFlag;
}
export enum ActivityBarKey {
    Home = 'home',
    AppointmentCancellationReasons = 'appointmentCancellationReasons',
    EncounterReasons = 'encounterReasons',
    AdjustmentReasons = 'adjustmentReasons',
    LocationsOfCare = 'locationsOfCare',
    Payers = 'payers',
    ProcedureDetails = 'procedure',
    Programs = 'sliding-fee',
    StatementConfiguration = 'statementConfiguration',
    Subscriptions = 'subscriptions',
    Users = 'users',
}
function ActivityBar(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();
    const params = useParams<{ tenantId: string }>();
    const { tenantId } = params;

    const taskCounts = useSelector(selectTaskCountsByPage);

    const activities: IActivity[] = [
        {
            iconName: 'HomeSolid',
            keyString: 'home',
            path: `/${tenantId}`,
            tooltip: 'Home',
            exact: true,
            permissions: permissionsAsList,
        },
        {
            iconName: 'RemoveEvent',
            keyString: 'appointmentCancellationReasons',
            path: `/${tenantId}/appointmentCancellationReasons`,
            tooltip: 'Appointment Cancellation Reasons',
            exact: false,
            permissions: [Permission.AppointmentCancellationReason],
            notifications: taskCounts[ActivityBarKey.AppointmentCancellationReasons],
        },
        {
            iconName: 'ClipboardList',
            keyString: 'encounters',
            path: `/${tenantId}/encounterReasons`,
            tooltip: 'Encounter Reasons',
            exact: false,
            permissions: [Permission.EncounterReasons],
            notifications: taskCounts[ActivityBarKey.EncounterReasons],
        },
        {
            iconName: 'ClipboardList',
            keyString: 'adjustment-reasons',
            path: `/${tenantId}/adjustment-reasons`,
            tooltip: 'Patient Adjustment Reasons',
            exact: false,
            permissions: [],
            notifications: taskCounts[ActivityBarKey.AdjustmentReasons],
        },
        {
            iconName: 'CityNext',
            keyString: 'locationsOfCare',
            path: `/${tenantId}/locationsOfCare`,
            tooltip: 'Locations of Care',
            exact: false,
            permissions: [Permission.LocationsOfCare],
            notifications: taskCounts[ActivityBarKey.LocationsOfCare],
        },

        {
            iconName: 'Money',
            keyString: 'payers',
            path: `/${tenantId}/payers`,
            tooltip: 'Payers',
            exact: false,
            permissions: [Permission.Payers],
        },

        {
            iconName: 'List',
            keyString: 'procedure',
            path: `/${tenantId}/procedures`,
            tooltip: 'Procedure Details',
            exact: false,
            permissions: [Permission.ProcedureDetails],
        },

        {
            iconName: 'PageList',
            keyString: 'sliding-fee',
            path: `/${tenantId}/sliding-fee`,
            tooltip: 'Programs',
            exact: false,
            permissions: [Permission.Programs],
        },
        {
            iconName: 'Articles',
            keyString: 'statementConfiguration',
            path: `/${tenantId}/statementConfiguration`,
            tooltip: 'Statement Configuration',
            permissions: [Permission.StatementConfiguration],
            featureFlag: FeatureFlag.AutomatedStatementsConfiguration,
            exact: false,
        },
        {
            iconName: 'TaskManager',
            keyString: 'subscriptions',
            path: `/${tenantId}/subscriptions`,
            tooltip: 'Subscriptions',
            exact: false,
            permissions: [Permission.DentalSubscription],
            notifications: taskCounts[ActivityBarKey.Subscriptions],
        },
        {
            iconName: 'AccountManagement',
            keyString: 'users',
            path: `/${tenantId}/users`,
            tooltip: 'Users',
            exact: false,
            permissions: [Permission.Users],
        },
    ];

    const { activityBarExpanded } = useSelector((state) => state.ui);

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity) => (
        <ActivityBarLink key={activity.keyString} expanded={activityBarExpanded} {...activity} />
    ));
    const wrapperClassName = activityBarExpanded ? 'activityBar activityBar--expanded' : 'activityBar';
    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${props.theme?.semanticColors.bodyDivider}`,
    };
    const expanderIcon = {
        iconName: activityBarExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };
    const _setExpanded = () => {
        dispatch(setActivityBarExpanded(!activityBarExpanded));
    };
    if (activityLinks.length > 0) {
        return (
            <Stack className={wrapperClassName} style={wrapperStyle}>
                <Stack.Item align={activityBarExpanded ? 'end' : 'center'}>
                    <IconButton onClick={_setExpanded} iconProps={expanderIcon} styles={{ root: { padding: '0px 24px' } }} />
                </Stack.Item>
                <div style={{ flex: 1 }}>{activityLinks}</div>
            </Stack>
        );
    }
    return <></>;
}

export default ActivityBar;
