import { IExtendedRouteProps } from '.';
import { Route } from 'react-router-dom';
import { SidebarLayout, NoSidebarLayout } from './layouts';
import usePermissions from 'hooks/usePermissions';
import { Icon, Text, Stack, useTheme } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';

export const RouteWrapper = ({
    component: Component,
    permissions,
    layout = 'noSideBar',
    featureFlag,
    ...rest
}: IExtendedRouteProps) => {
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);
    const hasFeatureFlag = featureFlag ? featureFlags.has(featureFlag) : true;

    const [hasPermissions] = usePermissions(permissions);

    if (!Component) {
        return null;
    }

    const layouts = {
        noSideBar: NoSidebarLayout,
        sideBar: SidebarLayout,
    };

    const Layout = layouts[layout];
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>{(hasPermissions || !permissions?.length) && hasFeatureFlag ? <Component {...props} /> : <AccessDenied />}</Layout>
            )}
        />
    );
};

export function AccessDenied() {
    const theme = useTheme();
    return (
        <Stack style={{ height: '100%' }} verticalAlign="center" horizontalAlign="center">
            <Stack
                className="ms-depth-16"
                horizontal
                horizontalAlign="center"
                style={{
                    overflow: 'hidden',
                    borderRadius: 5,
                }}
            >
                <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                        width: 100,
                        height: '100%',
                        background: theme.palette.red,
                    }}
                >
                    <Icon
                        iconName="Lock"
                        styles={{
                            root: { fontSize: 40, color: '#FFF', fontWeight: 'bold', textShadow: '0 3px 5px rgba(0,0,0,0.25)' },
                        }}
                    />
                </Stack>
                <Stack style={{ background: theme.palette.neutralLighterAlt, padding: '10px 20px' }} tokens={{ childrenGap: 10 }}>
                    <Text variant="xLargePlus">Access Denied</Text>
                    <Stack.Item>
                        <Text variant="medium" block>
                            You do not currently have acess to this page.
                        </Text>
                        <Text variant="medium">Please contact your administrator for more information.</Text>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    );
}
