import { Dictionary } from '@reduxjs/toolkit';
import { IProcedureCategory } from 'api/models/procedure-category.model';
import IProcedureDetail from 'api/models/procedure-details.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

// Initial state definition and type

type ProcedureDetailsState = {
    loading: LoadingStatus;
    loadingProcedureCategories: LoadingStatus;
    data: Dictionary<IProcedureDetail>;
    loadingProcedureDetail: LoadingStatus;
    selectedProcedureDetail?: IProcedureDetail;
    procedureCategories: IProcedureCategory[];
    savingProcedureDetail: LoadingStatus;
    procedureDetailsSearch?: string;
    error?: string;
    excelDataUploaded?: boolean;
    excelDataUploading: LoadingStatus;
    isProcedureActive: boolean;
};

export const initialProcedureDetailsState: ProcedureDetailsState = {
    data: {},
    procedureCategories: [],
    loading: LoadingStatus.Idle,
    loadingProcedureCategories: LoadingStatus.Idle,
    loadingProcedureDetail: LoadingStatus.Idle,
    savingProcedureDetail: LoadingStatus.Idle,
    excelDataUploading: LoadingStatus.Idle,
    isProcedureActive: true,
};

export default ProcedureDetailsState;
