import { createSelector, Dictionary } from '@reduxjs/toolkit';
import { IProviderProfile } from 'api/models/providerProfile.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { RootState } from 'state/store';
import { selectSubscriptionUsersAsList } from '../subscriptions/users/users.selectors';

export const selectProviderProfiles = (state: RootState): Dictionary<IProviderProfile> => state.providerProfiles.data;
export const selectProviderProfilesLoading = (state: RootState): LoadingStatuses => state.providerProfiles.loading;

export const selectProviderProfilesAsList = createSelector(selectProviderProfiles, (data) => {
    if (data) return map(data);
    return [];
});

export const selectSelectedProviderProfile = (state: RootState): IProviderProfile | undefined =>
    state.providerProfiles.selectedProviderProfile;

export const selectProvidersNotInUse = createSelector(selectProviderProfiles, selectSubscriptionUsersAsList, selectSelectedProviderProfile, (providerProfiles, users, selectedProvider) => {
    const providerProfilesIds = Object.keys(providerProfiles);
    const usersIds = users.map((user) => user.identity.providerId);
    const providerIdsNotInUse = providerProfilesIds.filter((id) => !usersIds.includes(id) || id === selectedProvider?.id);
    return providerIdsNotInUse.map((id) => providerProfiles[id]);
});
