import { Checkbox, Dropdown, Label, Stack, Text, TextField, Toggle } from '@fluentui/react';
import { TModal } from 'components';
import { useSelector, useValidation } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { upsertEditAdjustmentReason } from 'state/slices/adjustment-reasons/adjustment-reasons.actions';
import {
    selectAdjustmentCategoryOptions,
    selectAdjustmentReasonsEditItem,
    selectAdjustmentReasonsEditItemSaving,
    selectAdjustmentReasonsEditModalIsAdding,
    selectAdjustmentReasonsEditModalOpen,
} from 'state/slices/adjustment-reasons/adjustment-reasons.selectors';
import {
    cleanupAdjustmentReasonEditModal,
    editAdjustmentReasonPropertyValue,
    setAdjustmentReasonEditModalOpen,
} from 'state/slices/adjustment-reasons/adjustment-reasons.slice';

export default function AdjustmentReasonsModal() {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const isOpen = useSelector(selectAdjustmentReasonsEditModalOpen);

    const categoryOptions = useSelector(selectAdjustmentCategoryOptions);
    const editAdjustmentReason = useSelector(selectAdjustmentReasonsEditItem);
    const savingAdjustmentReason = useSelector(selectAdjustmentReasonsEditItemSaving);

    const isAddingAdjustmentReason = useSelector(selectAdjustmentReasonsEditModalIsAdding);

    const isSavingAdjustmentReason = savingAdjustmentReason === LoadingStatus.Pending;

    function onSave() {
        if (editAdjustmentReason)
            dispatch(upsertEditAdjustmentReason({ tenantId, isAddingAdjustmentReason, adjustmentReason: editAdjustmentReason }));
    }

    function onDismiss() {
        dispatch(setAdjustmentReasonEditModalOpen(false));
    }

    function onDismissed() {
        dispatch(cleanupAdjustmentReasonEditModal());
        cleanupValidationErrors();
    }

    const [errors, submit, cleanupValidationErrors] = useValidation(
        [
            {
                fieldName: 'Adjustment Reason Name',
                value: editAdjustmentReason?.displayName,
                validation: ['required'],
            },
            {
                fieldName: 'Associated Adjustment Category',
                value: editAdjustmentReason?.adjustmentCategoryId,
                validation: ['required'],
            },
            {
                fieldName: 'Adjustment Reason Name Length',
                value: editAdjustmentReason?.displayName,
                validation: ['characterLimit'],
                itemOptions: { characterLimit: 30 },
            },
        ],
        onSave,
    );

    return (
        <TModal
            title={`${isAddingAdjustmentReason ? 'Add' : 'Edit'} Adjustment Reason`}
            modalProps={{ isOpen, onDismissed, onDismiss }}
            isDraggable
            mainButtons={[
                {
                    text: isSavingAdjustmentReason ? 'Saving' : 'Save',
                    iconProps: { iconName: 'Save' },
                    primary: true,
                    disabled: isSavingAdjustmentReason,
                    onClick: submit,
                },
                { text: 'Cancel', iconProps: { iconName: 'Cancel' }, onClick: onDismiss },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} tokens={{ childrenGap: 10 }} grow>
                {!isAddingAdjustmentReason && (
                    <Stack.Item grow>
                        <Toggle
                            styles={{ root: { margin: 0 } }}
                            label="Is Active"
                            onText={'Active '}
                            offText={'Inactive'}
                            checked={!editAdjustmentReason?.isDeleted}
                            onChange={() => {
                                dispatch(
                                    editAdjustmentReasonPropertyValue({
                                        prop: 'isDeleted',
                                        value: !editAdjustmentReason?.isDeleted,
                                    }),
                                );
                            }}
                        />
                    </Stack.Item>
                )}
                <Stack.Item grow>
                    <TextField
                        value={editAdjustmentReason?.displayName ?? ''}
                        onChange={(ev, value) => {
                            dispatch(editAdjustmentReasonPropertyValue({ prop: 'displayName', value }));
                        }}
                        errorMessage={
                            getValidationError(errors, 'Adjustment Reason Name')
                                ? 'Adjustment Reason Name is required.'
                                : getValidationError(errors, 'Adjustment Reason Name Length')
                                    ? 'Character length must be below 30.'
                                    : undefined 
                        }
                        description={`Used ${editAdjustmentReason?.displayName.length ?? 0} of 30 characters.`}
                        label="Adjustment Reason Name"
                        placeholder="Enter an adjustment reason name..."
                        required
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Dropdown
                        placeholder="(Select adjustment category)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    editAdjustmentReasonPropertyValue({
                                        prop: 'adjustmentCategoryId',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={
                            getValidationError(errors, 'Associated Adjustment Category')
                                ? 'Associated Adjustment Category is required.'
                                : undefined
                        }
                        options={categoryOptions}
                        selectedKey={editAdjustmentReason?.adjustmentCategoryId}
                        label="Associated Adjustment Category"
                        required
                    />
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }} grow>
                    <Stack.Item grow>
                        <Toggle
                            checked={editAdjustmentReason?.isCollectable}
                            label="Cost Report>Collectible?"
                            styles={{ root: { margin: 0 } }}
                            onText="Yes"
                            offText="No"
                            onChange={() => {
                                dispatch(
                                    editAdjustmentReasonPropertyValue({
                                        prop: 'isCollectable',
                                        value: !editAdjustmentReason?.isCollectable,
                                    }),
                                );
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Toggle
                            label="Positive Adjustment?"
                            onText="Yes"
                            offText="No"
                            checked={editAdjustmentReason?.isPositiveAdjustment}
                            onChange={() => {
                                dispatch(
                                    editAdjustmentReasonPropertyValue({
                                        prop: 'isPositiveAdjustment',
                                        value: !editAdjustmentReason?.isPositiveAdjustment,
                                    }),
                                );
                            }}
                        />

                        {getValidationError(errors, 'Applies To') ? (
                            <Text>A selection of either Patient and/or Insurance is required.</Text>
                        ) : undefined}
                    </Stack.Item>
                </Stack>
            </Stack>
        </TModal>
    );
}
