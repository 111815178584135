import { useRef, useCallback, useEffect, useState, useMemo, CSSProperties, FormEvent } from 'react';
import {
    IComboBoxProps,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    SelectableOptionMenuItemType,
    ISelectableOption,
    IComboBoxStyles,
    useTheme,
    Text,
    VirtualizedComboBox,
    mergeStyles,
    Icon,
} from '@fluentui/react';
import { uniqBy } from 'lodash';
import { useSelector } from 'hooks';
import { useBoolean } from '@uifabric/react-hooks';
import { useFuseSearch } from 'hooks/useFuseSearch';

export interface ISearchComboBoxOption extends IComboBoxOption {
    groupName?: string; //Used to create groups of options separated by headers and dividers
}

export interface ISearchComboFieldProps extends Omit<IComboBoxProps, 'autoComplete'> {
    maxResults?: number;
    threshold?: number;
    onSearch?: (search: string | undefined) => void;
    virutalized?: boolean;
    altColor?: boolean;
    optionName?: string;
    onClear?: () => void;
    searchComboWrapperStyle?: CSSProperties;
    options: ISearchComboBoxOption[];
    onIsSearching?: (isSearching: boolean) => void;
}

const comboBoxWrapper = mergeStyles({
    position: 'relative',
});

const SearchComboField = ({
    selectedKey,
    options,
    label,
    placeholder = '(Select)',
    maxResults = 35,
    onChange,
    multiSelect,
    threshold = 0.1,
    onSearch,
    virutalized,
    altColor,
    optionName,
    onRenderOption,
    onClear,
    searchComboWrapperStyle,
    onIsSearching,
    ...props
}: ISearchComboFieldProps): JSX.Element => {
    const theme = useTheme();
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);
    const hasMultipleSelectedKeys = Array.isArray(selectedKey);

    const comboBoxRef = useRef<IComboBox>(null);

    const { search, setSearch, results } = useFuseSearch({
        list: options,
        fuseOptions: { threshold, keys: ['text'] },
    });

    const [hovered, { setTrue: setHovering, setFalse: setNotHovering }] = useBoolean(false);

    const openMenu = useCallback(() => {
        comboBoxRef.current?.focus(true);
    }, []);

    const _onIsSearching = useCallback(
        (focused: boolean) => {
            if (onIsSearching) onIsSearching(focused);
        },
        [onIsSearching],
    );

    const getUnselectedOptions = useCallback(
        (options: ISearchComboBoxOption[]) =>
            options.filter((option) => {
                return hasMultipleSelectedKeys
                    ? (selectedKey as (number | string)[]).indexOf(option.key) === -1
                    : selectedKey !== option.key;
            }),
        [selectedKey],
    );

    const currentlySelectedEntities = options
        .filter((o) => {
            if (hasMultipleSelectedKeys) {
                return (selectedKey as (string | number)[]).indexOf(o.key) > -1;
            } else {
                return o.key === selectedKey;
            }
        })
        .map((option) => ({ ...option, text: `${option.text}${option.groupName ? ` (${option.groupName})` : ''}` }));

    const unselectedOptions = getUnselectedOptions(search ? results : options);

    const getGroupedOptions = useCallback(() => {
        const groups: Record<string, ISearchComboBoxOption[]> = {};
        const groupNames = uniqBy(options, (option) => option.groupName).map((option) => option?.groupName ?? 'Options');

        unselectedOptions.forEach((option) => {
            if (option.groupName) {
                if (!groups[`${option.groupName}`]) groups[`${option.groupName}`] = [];
                groups[`${option.groupName}`].push({ ...option });
            } else {
                if (!groups['Options']) groups['Options'] = [];
                groups['Options'].push({ ...option });
            }
        });

        return groupNames
            .map((groupName, index) => {
                const newOptions: ISearchComboBoxOption[] = [
                    {
                        key: `startingDivider${index + 2}`,
                        text: '',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: `header${index + 2}`,
                        text: groupName === 'Options' ? (optionName ? optionName : 'Options') : groupName,
                        itemType: SelectableOptionMenuItemType.Header,
                    },
                    {
                        key: `endingDivider${index + 2}`,
                        text: '',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                ];

                if (groupName === 'Options' && !multiSelect)
                    newOptions.push({
                        key: '',
                        text: placeholder ? placeholder : '(Select)',
                    });
                if (groups[groupName]) newOptions.push(...groups[groupName]);

                return groups[groupName]?.length
                    ? newOptions.length >= maxResults
                        ? newOptions.slice(0, maxResults)
                        : newOptions
                    : [];
            })
            .flat();
    }, [unselectedOptions, options]);

    const groupedOptions = getGroupedOptions();

    const allOptions: ISearchComboBoxOption[] = selectedKey
        ? [
              ...((hasMultipleSelectedKeys ? selectedKey.length : selectedKey !== undefined)
                  ? [
                        {
                            key: 'header1',
                            text: 'Currently Selected',
                            itemType: SelectableOptionMenuItemType.Header,
                        },
                        {
                            key: 'divider1',
                            text: '',
                            itemType: SelectableOptionMenuItemType.Divider,
                        },
                    ]
                  : []),
              ...currentlySelectedEntities,
              ...groupedOptions,
          ]
        : groupedOptions;

    const _onKeyDownCapture = useCallback(
        (event: React.KeyboardEvent<IComboBox>) => {
            if (
                event.code === 'Numpad8' ||
                event.code === 'ArrowUp' ||
                event.code === 'Numpad2' ||
                (event.code === 'ArrowDown' && unselectedOptions.length)
            ) {
                _onIsSearching(true);
            }
            if (props.onKeyDownCapture) props.onKeyDownCapture(event);
        },
        [props.onKeyDownCapture, unselectedOptions.length],
    );

    const clearSearch = () => {
        setSearch('');
        _onIsSearching(false);
    };

    const updateOptions = (search: string) => {
        setSearch(search.trim().toLowerCase());
    };

    const _onRenderOption = (props: ISelectableOption | undefined) => {
        if (props) {
            if (props.key === '') return <Text style={{ color: theme.palette.neutralSecondaryAlt }}>{props?.text}</Text>;
            switch (props.itemType) {
                case SelectableOptionMenuItemType.Header:
                case SelectableOptionMenuItemType.Divider:
                    return <span>{props.text}</span>;
                default:
                    return onRenderOption ? onRenderOption(props) : <Text>{props.text}</Text>;
            }
        }
        return null;
    };

    const getStyles = (value: unknown | undefined): Partial<IComboBoxStyles> => ({
        optionsContainerWrapper: {
            backgroundColor: selectedTheme === 'dark' && altColor ? 'rgb(52, 52, 52)' : '',
        },
        input: {
            color: value === '' || value === null || value === undefined ? theme?.palette.neutralSecondary : theme?.palette.black,
        },
        callout: {
            // maxHeight: '50vh',
            selectors: {
                '.ms-Callout-main': {
                    maxHeight: '50vh !important',
                },
            },
        },
    });

    const comboBoxClear = mergeStyles({
        position: 'absolute',
        right: 32,
        bottom: 7,
        cursor: 'pointer',
        color: theme.palette.black,
        backgroundColor: theme.palette.white,
        display: hovered ? '' : 'none',
        paddingRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
    });
    const ComboBoxComponent = virutalized ? VirtualizedComboBox : ComboBox;

    return (
        <div className={comboBoxWrapper} style={searchComboWrapperStyle ?? {}}>
            <ComboBoxComponent
                {...props}
                componentRef={comboBoxRef}
                label={label}
                placeholder={placeholder}
                selectedKey={selectedKey}
                multiSelect={multiSelect}
                autoComplete="off"
                allowFreeform
                styles={{ ...getStyles(selectedKey), ...props.styles }}
                options={allOptions}
                onInput={(e) => {
                    const value = (e.target as unknown as { value: string }).value;
                    updateOptions(value);
                    openMenu();

                    // The only way we can really tell if the user is searching or not is after the user types information,
                    // and then arrows or clicks on the given option. Otherwise the user "could" be trying to add a new option dynamically.

                    _onIsSearching(false);
                    if (props.onInput) props.onInput(e);
                }}
                inputMode="search"
                onMouseEnter={setHovering}
                onMouseLeave={setNotHovering}
                onBlur={clearSearch}
                text={search}
                onKeyDownCapture={_onKeyDownCapture}
                onRenderOption={_onRenderOption}
                onChange={(e, option, index, value) => {
                    //Unless the user is clicking away, we want the most relevant answer from the result list.
                    if (
                        option?.itemType !== SelectableOptionMenuItemType.Divider &&
                        option?.itemType !== SelectableOptionMenuItemType.Header &&
                        option?.itemType !== SelectableOptionMenuItemType.SelectAll
                    ) {
                        const shouldGetMostRelevant = unselectedOptions.length && !option;
                        if (e.type !== 'blur' && shouldGetMostRelevant) setSearch('');
                        if (e.type === 'change') _onIsSearching(false);

                        const newOption = e.type !== 'blur' ? (shouldGetMostRelevant ? unselectedOptions[0] : option) : undefined;

                        if (newOption && onChange) onChange(e, newOption, index, value);
                    }
                }}
            />
            {onClear && (hasMultipleSelectedKeys ? selectedKey.length : selectedKey !== undefined) ? (
                <Icon
                    title="Clear Selected"
                    className={comboBoxClear}
                    onMouseEnter={setHovering}
                    onMouseLeave={setNotHovering}
                    iconName="Cancel"
                    onClick={onClear}
                />
            ) : null}
        </div>
    );
};

export default SearchComboField;
