import {
    Stack,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    ConstrainMode,
    Link,
    Toggle,
    MessageBarType,
    DetailsRow,
    useTheme,
    TooltipHost,
    Spinner,
} from '@fluentui/react';
import membershipApi from 'api/membership.api';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    editAdjustmentReason,
    getAdjustmentCategories,
    getAdjustmentReasons,
} from 'state/slices/adjustment-reasons/adjustment-reasons.actions';
import {
    AdjustmentReasonView,
    selectAdjustmentCategoriesLoadingStatus,
    selectAdjustmentReasonsLoadingStatus,
    selectAdjustmentReasonsLookup,
    selectFilteredAdjustmentReasonViews,
} from 'state/slices/adjustment-reasons/adjustment-reasons.selectors';
import { setAdjustmentReason, setAdjustmentReasonMessageBar } from 'state/slices/adjustment-reasons/adjustment-reasons.slice';
import { useAppDispatch } from 'state/store';
import AdjustmentReasonsModal from './AdjustmentReasonsModal';

export default function AdjustmentReasonsList() {
    const dispatch = useAppDispatch();
    const { palette } = useTheme();
    const { tenantId } = useParams<RouteParams>();

    const adjustmentReasonViews = useSelector(selectFilteredAdjustmentReasonViews);
    const adjustmentReasonsLoading = useSelector(selectAdjustmentReasonsLoadingStatus);
    const adjustmentCategoriesLoading = useSelector(selectAdjustmentCategoriesLoadingStatus);

    const isLoading = adjustmentReasonsLoading === LoadingStatus.Pending || adjustmentCategoriesLoading === LoadingStatus.Pending;

    useEffect(() => {
        dispatch(getAdjustmentReasons({ tenantId }));
        dispatch(getAdjustmentCategories({ tenantId }));
    }, []);

    return (
        <>
            <AdjustmentReasonsModal />
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10, display: 'flex', flex: 1 }} grow>
                <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <SortableDetailsList<AdjustmentReasonView>
                            columns={[
                                {
                                    key: 'adjustmentReasonDisplayName',
                                    name: 'Reason',
                                    fieldName: 'displayName',
                                    minWidth: 150,
                                    maxWidth: 180,
                                    isResizable: true,
                                    onRender: (item) => {
                                        if (!item) return null;
                                        return (
                                            <Link onClick={() => dispatch(editAdjustmentReason(item.id))}>
                                                {!item.isDeleted ? `${item.displayName}` : <i>{item.displayName}</i>}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    key: 'associatedCategory',
                                    name: 'Category',
                                    fieldName: 'adjustmentCategoryDisplayName',
                                    minWidth: 90,
                                    maxWidth: 150,
                                    isResizable: true,
                                    onRender: (item) => {
                                        if (!item) return null;
                                        return (
                                            <span>
                                                {!item.isDeleted ? (
                                                    `${item.adjustmentCategoryDisplayName}`
                                                ) : (
                                                    <i>{item.adjustmentCategoryDisplayName}</i>
                                                )}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    key: 'costReportCollectible?',
                                    name: 'Cost Report>Collectable?',
                                    fieldName: 'isCollectable',
                                    minWidth: 100,
                                    maxWidth: 190,
                                    isResizable: true,
                                    onRender: (item) => {
                                        if (!item) return null;
                                        return <AdjustmentReasonIsCollectableToggle item={item} />;
                                    },
                                },
                            ]}
                            onRenderRow={(props) => {
                                if (!props) return null;
                                return (
                                    <TooltipHost
                                        content={props.item.isDeleted ? 'This Adjustment Reason is inactive.' : undefined}
                                    >
                                        <DetailsRow
                                            {...props}
                                            styles={{
                                                root: {
                                                    backgroundColor: props.item.isDeleted ? palette.neutralLighterAlt : undefined,
                                                },
                                            }}
                                        />
                                    </TooltipHost>
                                );
                            }}
                            selectionMode={SelectionMode.none}
                            items={adjustmentReasonViews}
                            shimmerLines={3}
                            enableShimmer={isLoading}
                            onItemInvoked={(item) => {
                                if (item) dispatch(editAdjustmentReason(item.id));
                            }}
                            constrainMode={ConstrainMode.horizontalConstrained}
                            stickyHeader={true}
                        />
                    </ScrollablePane>
                </div>
            </Stack>
        </>
    );
}

type AdjustmentReasonIsCollectableProps = {
    item: AdjustmentReasonView;
};

function AdjustmentReasonIsCollectableToggle({ item }: AdjustmentReasonIsCollectableProps) {
    const dispatch = useAppDispatch();
    const { tenantId } = useParams<RouteParams>();

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
    const isLoading = loadingStatus === LoadingStatus.Pending;

    const adjustmentReasonLookup = useSelector(selectAdjustmentReasonsLookup);

    async function toggleAdjustmentReasonIsCollectibleAndSave(adjustmentReason: IAdjustmentReason) {
        try {
            setLoadingStatus(LoadingStatus.Pending);
            const response = await membershipApi.updateAdjustmentReason(tenantId, adjustmentReason);

            dispatch(
                setAdjustmentReasonMessageBar({
                    messageBarMessage: 'Updated adjustment reason successfully.',
                    messageBarType: MessageBarType.success,
                }),
            );
            setLoadingStatus(LoadingStatus.Completed);
            return response.data;
        } catch (e) {
            dispatch(
                setAdjustmentReasonMessageBar({
                    messageBarMessage: 'Something went wrong while trying to save adjustment reason.',
                    messageBarType: MessageBarType.error,
                }),
            );
            setLoadingStatus(LoadingStatus.Failed);
        }
    }

    return (
        <Stack horizontal grow>
            <Toggle
                styles={{ root: { margin: 0 }, label: { padding: 0 } }}
                checked={item.isCollectable}
                disabled={isLoading}
                onChange={(_, checked) => {
                    const adjustmentReason: IAdjustmentReason = {
                        ...adjustmentReasonLookup[item.id],
                        isCollectable: checked,
                    };
                    toggleAdjustmentReasonIsCollectibleAndSave(adjustmentReason).then((data) => {
                        if (data) dispatch(setAdjustmentReason(data));
                    });
                }}
                inlineLabel
                onText="Yes"
                offText="No"
            />
            {isLoading ? <Spinner title="Saving..."/> : null}
        </Stack>
    );
}
